/* 
 * @author Mateusz Mieszczak <mateusz.mieszczak at bossbyte.com>
 * @copyright BossByte Web Application
 */


app.directive('simpleCaptcha', function() {
    return {
        restrict: 'E',
        templateUrl: '/themes/czelej/assets/universal/js/directives/simpleCaptcha.html',
		scope: {
			valid: '=' 
		},
        controller: function($scope) {
            var base = 10;
			
            var show = Math.random() > 0.5;
            
            var value = function(max){
                return Math.floor(max * Math.random());
            };
            
            var int = function(str){
                return parseInt(str, 10);
            };
            
            $scope.a = {
                value: show? undefined : 1 + value(base-1),
                input: show
            };
            $scope.b = {
                value: !show? undefined : 1 + value(base-1),
                input: !show
            };
            $scope.operation = '+';
            
            $scope.result = base + value(base);
            
            var a = $scope.a;
            var b = $scope.b;
            var result = $scope.result;
            
            var checkValidity = function(){
                if (a.value && b.value) {
                    var calc = int(a.value) + int(b.value);
                    $scope.valid = calc == result;
                } else {
                    $scope.valid = false;
                }
				if(!!$scope.$$phase)
					$scope.$apply(); // needed to solve 2 cycle delay problem;
            };
            
            
            $scope.$watch('a.value', function(){    
                checkValidity();
            });
            
            $scope.$watch('b.value', function(){    
                checkValidity();
            });
            
            
            
        }
    };
});