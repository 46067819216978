app.controller('ContactForm', function ($scope, $http) {
	$scope.message = {
		valid:false
	};
	
	$scope.formParams = {
		show: true,
		loading: false
	};
	$scope.sendMessage = function(formValid){
		if(!formValid){
			return;
		}
		$scope.formParams.loading = true;
		$scope.formParams.show = false;
		$http.post('/instance', {method:'contactFormMessage', params:$scope.message})
			.success(function(result){
				$scope.formParams.loading = false;
			});
	};
});